dmx.Component('local-manager', {

    initialData: {
        data: {}
    },

    attributes: {},

    methods: {
        set: function(key, value) {
            window.localStorage.setItem('dmxState-' + key, JSON.stringify(value));
            this.getData();
        },

        remove: function(key) {
            window.localStorage.removeItem('dmxState-' + key);
            this.getData();
        }
    },

    render: function(node) {
        this.getData();
    },

    getData: function() {
        this.set('data', Object.keys(window.localStorage).reduce(function(data, key) {
            if (/^dmxState-/.test(key)) {
                try {
                    data[key.substr(9)] = JSON.parse(window.localStorage.getItem(key));
                } catch (e) {
                    console.warn('Error parsing JSON: ' + window.localStorage.getItem(key));
                }
            }

            return data;
        }, {}));
    }

});
